<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import appConfig from "../../../app.config";
import axios from 'axios';

import Swal from "sweetalert2";
  export default {
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        load: false,
        email: "",
        password: "",
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
      };
    },
    validations: {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    },
    computed: {

    },
    created() {

    },
    methods: {
      async resend() {
               this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/auth/resend/email").catch((error) => {

this.load = false;
                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
this.load = false;
                if (response.data.status == true) {

                  Swal.fire("Success!", response.data.data, "success");


                }

            })
        },



            async verifyemail () {


       this.load = true;
try {
  
       
  var data = await this.$store.dispatch('verifyemailcode', {
      code: this.password ,
     
   });  

         this.load = false;
         Swal.fire("Good job!", data.message, "success");
        this.$router.push({name: 'dashboard'});
       


       
} catch (error) {
  this.load = false;
 
    this.$store.commit('SetAuthLogout')
    

        Swal.fire("Error", error.response.data.data.message, "error");
       
   this.$router.push({name: 'login'});

}





     },


   


    },
  };
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="40" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Best bill payment in nigeria
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Email Verify!</h5>
                  <p class="text-muted">Please Verify Your Email</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                  <div>

                  </div>

                  <form @submit.prevent="tryToLogIn">
                    

                    <div class="mb-3">
                      <div class="float-end">
                        <a @click="resend" class="text-muted">Resend Code</a>
                      </div>
                      <label class="form-label" for="password-input">Verification Code</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="number" v-model="password" class="form-control pe-5" placeholder="Enter Verification Code"
                          id="password-input" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit" @click="verifyemail" :disabled="load">
                      <div class="spinner-border spinner-border-sm" role="status" v-if="load"> 
  <span class="sr-only">Loading...</span>
</div>
                      Verify
                      </button>
                    </div>

                   
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

          
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Gopay. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Go Softwares
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>